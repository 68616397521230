import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class ApiProvider {
	/* ------ 27 Funções de conexão ------ */

	private readonly url: string =
		"https://h0jlj6p8i6.execute-api.sa-east-1.amazonaws.com/prod"; /* URL da API */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	private readonly headers = new HttpHeaders({
		"Content-Type": "application/x-www-form-urlencoded",
	}); /* Header de Conexão */

	constructor(private http: HttpClient) {}

	/* ------ Função de autenticação do Token ------ */
	public autenticarToken(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/autenticar`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de autenticação de Usuário e Senha ------ */
	public autenticarUsuario(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/autenticar-usuario`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Funçãao que grava o Histórico de Acesso ------ */
	public gravarHistorico(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/gravar-historico-de-acesso`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Clientes ------ */
	public buscarClientes(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-clientes`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Cliente por ID ------ */
	public buscarClientePorID(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-cliente-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Produtos ------ */
	public buscarProdutos(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-produtos`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Produtos Mais Vendidos ------ */
	public buscarProdutosMaisVendidos(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-produtos-mais-vendidos`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição dos Produtos Mais Devolvidos ------ */
	public buscarProdutosMaisDevolvidos(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-produtos-mais-devolvidos`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Produto por ID ------ */
	public buscarProdutoPorID(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-produto-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Vendas ------ */
	public buscarVendas(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-vendas`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Venda por ID ------ */
	public buscarVendaPorID(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-venda-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Vendas por Mês ------ */
	public buscarVendasPorMes(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-vendas-por-mes`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de OS por Mês ------ */
	public buscarOSPorMes(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-os-por-mes`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Faturamento por Tipo ------ */
	public buscarFaturamentoPorTipo(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-faturamento-por-tipo`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Faturamento por Vendedor ------ */
	public buscarFaturamentoPorVendedor(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-faturamento-por-vendedor`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Faturamento Total ------ */
	public buscarFaturamentoTotal(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-faturamento-total`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Faturamento por Situação ------ */
	public buscarFaturamentoPorSituacao(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-faturamento-por-situacao`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Faturamento por Forma de Pagamento ------ */
	public buscarFaturamentoPorPagamento(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-faturamento-por-pagamento`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Totais da página Home ------ */
	public buscarTotais(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-totais`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Total de Vendas por Dia ------ */
	public buscarTotalVendasPorDia(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-total-vendas-por-dia`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Itens da Venda ------ */
	public buscarItensDaVenda(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-itens-da-venda`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Formas de Pagamento da Venda ------ */
	public buscarFormasDePagamentoDaVenda(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-formas-de-pagamento`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Ordens de Serviço ------ */
	public buscarOrdensDeServico(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(`${this.url}/buscar-os`, JSON.parse(params), {
				headers: this.headers,
			});
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Ordem de Serviço por ID ------ */
	public buscarOrdemDeServicoPorID(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-os-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Total de Ordens de Serviço por Dia ------ */
	public buscarTotalOrdensDeServicoPorDia(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-total-os-por-dia`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Itens da Ordem de Serviço ------ */
	public buscarItensDaOrdemDeServico(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-itens-da-os`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Contas a Receber ------ */
	public buscarContasAReceber(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-ctr`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Contas a Receber por ID ------ */
	public buscarContaAReceberPorID(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-ctr-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição  de Total de Contas a Receber por Mês ------ */
	public buscarTotalContasAReceberPorMes(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-total-ctr-por-mes`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Contas a Pagar ------ */
	public buscarContasAPagar(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-ctp`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Contas a Pagar por Semana ------ */
	public buscarContasAPagarPorSemana(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-ctp-por-semana`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Conta a Pagar por ID ------ */
	public buscarContaAPagarPorID(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-ctp-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Total de Contas a Receber por Mês ------ */
	public buscarTotalContasAPagarPorMes(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-total-ctp-por-mes`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Caixa ------ */
	public buscarCaixa(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-caixa`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Função de requisição de Caixa por ID ------ */
	public buscarCaixaPorID(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-caixa-por-id`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Busca o Total de Caixa por Dia ------ */
	public buscarTotalCaixaPorDia(
		params: string
	): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-total-caixa-por-dia`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}

	/* ------ Busca os Portadores ------ */
	public buscarPortadores(params: string): Observable<Array<any> | any> {
		try {
			return this.http.post(
				`${this.url}/buscar-portadores`,
				JSON.parse(params),
				{ headers: this.headers }
			);
		} catch (error) {
			return error;
		}
	}
}
