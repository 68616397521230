import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()

export class ConexaoProvider {

	constructor(
    private storage: Storage
  ) {
    this.storage.create();
  }

	public save(key: string, conexao: any) {
		return this.storage.set(key, conexao);
	}

	public async get(key: string) {
		return await this.storage.get(key);
	}

	public excluir(key: string) {
		this.storage.remove(key);
	}

	public buscar(){
		const con = new Conexao();

		return this.storage.forEach((value: string, key: string, iterationNumber: number) => {
			if (key === 'host') {
				con.host = value;
			} else if (key === 'banco') {
				con.banco = value;
			} else if (key === 'usuario') {
				con.usuario = value;
			} else if (key === 'senha') {
				con.senha = value;
			} else if (key === 'porta') {
				con.porta = value;
			} else if (key === 'nome') {
				con.nome = value;
			}
		}).then(() => Promise.resolve(con)).catch((error) => Promise.reject(error));
	}

}

export class Conexao {
	host: string;
	porta: string;
	banco: string;
	usuario: string;
	senha: string;
	nome: string;
}
