import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { IonicModule, NavParams } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceProvider } from 'src/app/providers/service-provider';
import { HttpClientModule } from '@angular/common/http';
import { ConexaoProvider } from 'src/app/providers/conexao';
import { Storage } from '@ionic/storage-angular';
import { ApiProvider } from 'src/app/providers/api/api';
import { PermissoesProvider } from 'src/app/providers/config-permissoes';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    NavParams,
    ServiceProvider,
    ConexaoProvider,
    PermissoesProvider,
    Storage,
    ApiProvider,
  ]
})
export class HomeModule { }
