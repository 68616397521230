import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'pes-filial',
    loadChildren: () => import('./modules/pes-filial/pes-filial.module').then(m => m.PesFilialModule)
  },
  {
    path: 'pes-cliente',
    loadChildren: () => import('./modules/pes-cliente/pes-cliente.module').then(m => m.PesClienteModule)
  },
  {
    path: 'info-cliente',
    loadChildren: () => import('./modules/info-cliente/info-cliente.module').then(m => m.InfoClienteModule)
  },
  {
    path: 'pes-produto',
    loadChildren: () => import('./modules/pes-produto/pes-produto.module').then(m => m.PesProdutoModule)
  },
  {
    path: 'info-produto',
    loadChildren: () => import('./modules/info-produto/info-produto.module').then(m => m.InfoProdutoModule)
  },
  {
    path: 'pes-venda',
    loadChildren: () => import('./modules/pes-venda/pes-venda.module').then(m => m.PesVendaModule)
  },
  {
    path: 'info-venda',
    loadChildren: () => import('./modules/info-venda/info-venda.module').then(m => m.InfoVendaModule)
  },
  { path: 'modal-filtro', loadChildren: () => import('./modules/modal-filtro/modal-filtro.module').then(m => m.ModalFiltroModule) },
  { path: 'pes-os', loadChildren: () => import('./modules/pes-os/pes-os.module').then(m => m.PesOsModule) },
  { path: 'filtro-os', loadChildren: () => import('./modules/filtro-os/filtro-os.module').then(m => m.FiltroOsModule) },
  { path: 'info-os', loadChildren: () => import('./modules/info-os/info-os.module').then(m => m.InfoOsModule) },
  { path: 'pes-ctr', loadChildren: () => import('./modules/pes-ctr/pes-ctr.module').then(m => m.PesCtrModule) },
  { path: 'info-ctr', loadChildren: () => import('./modules/info-ctr/info-ctr.module').then(m => m.InfoCtrModule) },
  { path: 'filtro-ctr', loadChildren: () => import('./modules/filtro-ctr/filtro-ctr.module').then(m => m.FiltroCtrModule) },
  { path: 'pes-ctp', loadChildren: () => import('./modules/pes-ctp/pes-ctp.module').then(m => m.PesCtpModule) },
  { path: 'info-ctp', loadChildren: () => import('./modules/info-ctp/info-ctp.module').then(m => m.InfoCtpModule) },
  { path: 'filtro-ctp', loadChildren: () => import('./modules/filtro-ctp/filtro-ctp.module').then(m => m.FiltroCtpModule) },
  { path: 'pes-caixa', loadChildren: () => import('./modules/pes-caixa/pes-caixa.module').then(m => m.PesCaixaModule) },
  { path: 'info-caixa', loadChildren: () => import('./modules/info-caixa/info-caixa.module').then(m => m.InfoCaixaModule) },
  { path: 'filtro-caixa', loadChildren: () => import('./modules/filtro-caixa/filtro-caixa.module').then(m => m.FiltroCaixaModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
