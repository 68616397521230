import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
const headers = new HttpHeaders({ 'Content-Type' : 'application/x-www-form-urlencoded'});

/*
  Generated class for the ServiceProvider provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular DI.
*/
@Injectable()
export class ServiceProvider {

  api = 'http://www.bruningsistemas.com.br/bruningsistemas.com.br/bruningsistemas/';

  constructor(public http: HttpClient) {
  }

  listarClientes(params) {
    return this.http.post(this.api + 'consultaClientes.php', params, { headers });
  }

  /* ------------------ ! FAZER DEPOIS ! ------------------ */
  buscarTotais(params) {
    return this.http.post(this.api + 'buscarTotais.php', params, {headers});
  }

  buscarVendasMes(params) {
    return this.http.post(this.api + 'buscarVendasMes.php', params, {headers});
  }

  /* ------------------ ! FAZER DEPOIS ! ------------------ */
  buscarTotalVendasPorDia(params) {
    return this.http.post(this.api + 'buscarTotalVendasPorDia.php', params, {headers});
  }

  buscarTotalOSPorDia(params) {
    return this.http.post(this.api + 'buscarTotalOSPorDia.php', params, {headers});
  }

  buscarTotalCaixaPorDia(params) {
    return this.http.post(this.api + 'buscarTotalCaixaPorDia.php', params, {headers});
  }

  buscarTotalCTRPorMes(params) {
    return this.http.post(this.api + 'buscarTotalCTRPorMes.php', params, {headers});
  }

  buscarTotalCTPPorMes(params) {
    return this.http.post(this.api + 'buscarTotalCTPPorMes.php', params, {headers});
  }

  listarProdutos(params) {
    return this.http.post(this.api + 'consultaProdutos.php', params, {headers});
  }

  listarVendas(params) {
    return this.http.post(this.api + 'consultaVendas.php', params, {headers});
  }

  listarOS(params) {
    return this.http.post(this.api + 'consultaOS.php', params, {headers});
  }

  listarCaixa(params) {
    return this.http.post(this.api + 'consultaCaixa.php', params, {headers});
  }

  listarCTR(params) {
    return this.http.post(this.api + 'consultaCTR.php', params, {headers});
  }

  listarCTP(params) {
    return this.http.post(this.api + 'consultaCTP.php', params, {headers});
  }

  carregarPortadores(params) {
    return this.http.post(this.api + 'consultaPortadores.php', params, {headers});
  }

  buscarCliente(params) {
    return this.http.post(this.api + 'buscarCliente.php', params, {headers});
  }

  buscarProduto(params) {
    return this.http.post(this.api + 'buscarProduto.php', params, {headers});
  }

  buscarVenda(params) {
    return this.http.post(this.api + 'buscarVenda.php', params, {headers});
  }

  buscarFormaDePagto(params) {
    return this.http.post(this.api + 'buscarFormaPagamento.php', params, {headers});
  }

  buscarOS(params) {
    return this.http.post(this.api + 'buscarOS.php', params, {headers});
  }

  buscarCaixa(params) {
    return this.http.post(this.api + 'buscarCaixa.php', params, {headers});
  }

  buscarCTR(params) {
    return this.http.post(this.api + 'buscarCTR.php', params, {headers});
  }

  buscarCTP(params) {
    return this.http.post(this.api + 'buscarCTP.php', params, {headers});
  }

  buscarItensVenda(params) {
    return this.http.post(this.api + 'buscarItensVenda.php', params, {headers});
  }

  buscarItensOS(params) {
    return this.http.post(this.api + 'buscarItensOS.php', params, {headers});
  }

  buscaIpExterno(host) {
    const params = {host};
    return this.http.post(this.api + 'buscaIpExterno.php', params, {headers});
  }

	autenticar(token) {
		try {
			const params = { token };
      return this.http.post(this.api + 'autenticar.php', params, {headers});
		} catch (error) {
			alert(error.message);
		}
	}

  postData(params) {
    return this.http.post(this.api + 'apiCadastro.php', params, {headers});
  }

  gravaHistoricoAcesso(params) {
    return this.http.post(this.api + 'gravaHistoricoAcesso.php', params, {headers});
  }

}
