import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

@Injectable()
export class PermissoesProvider {
	constructor(private storage: Storage) {
		this.storage.create();
	}

	public save(key: string, conexao: any) {
		return this.storage.set(key, conexao);
	}

	public buscaTokenAtivo() {
		let token = "";
		return this.storage.forEach((value: string, key: string) => {
			if (key.indexOf('filial') > -1) {
				if (key !== 'filial53343946'){
					const filial : any = value;

					if (filial?.ativo){
						token = filial.token;
					}
				}
			}
		}).then(() => Promise.resolve(token)).catch((error) => Promise.reject(error));
	}

	public buscar() {
		const con = new Permissoes();

		return this.storage
			.forEach((value: string, key: string, iterationNumber: number) => {
				if (key === "cliente_listar") {
					con.cliente_listar = value;
				} else if (key === "produto_listar") {
					con.produto_listar = value;
				} else if (key === "venda_listar") {
					con.venda_listar = value;
				} else if (key === "os_listar") {
					con.os_listar = value;
				} else if (key === "ctr_listar") {
					con.ctr_listar = value;
				} else if (key === "ctp_listar") {
					con.ctp_listar = value;
				} else if (key === "caixa_listar") {
					con.caixa_listar = value;
				} else if (key === "trocar_filiais") {
					con.trocar_filiais = value;
				} else if (key === "dashboard_listar") {
					con.dashboard_listar = value;
				} else if (key === "custo_reposicao_exibir") {
					con.custo_reposicao_exibir = value;
				} else if (key === "login") {
					con.login = value;
				} else if (key === "password") {
					con.password = value;
				}
			})
			.then(() => Promise.resolve(con))
			.catch((error) => Promise.reject(error));
	}
}

export class Permissoes {
	custo_reposicao_exibir: string;
	dashboard_listar: string;
	cliente_listar: string;
	produto_listar: string;
	venda_listar: string;
	os_listar: string;
	ctr_listar: string;
	ctp_listar: string;
	caixa_listar: string;
	trocar_filiais: string;
	login: string;
	password: string;
}
